import { InjectionToken } from "@angular/core";
import { RoamEnvConfig } from "../models/env-config.model";

export const ROAM_ENV_CONFIG = new InjectionToken<RoamEnvConfig>(
  "roamstay-env-config",
);

export function provideRoamEnvConfig(value: RoamEnvConfig) {
  return {
    provide: ROAM_ENV_CONFIG,
    useValue: value,
  };
}
